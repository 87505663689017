import type { CountryEnum, VehicleTypeEnum } from '@apiTypes'
import { useTransportRestrictionStore } from '@store/transport-restrictions'

const restrictions = ref()
let loading = false

export function useTransportRestrictions() {
  const store = useTransportRestrictionStore()

  async function fetchRestrictions() {
    if (!restrictions.value && !loading) {
      loading = true

      restrictions.value = await store.fetchRestrictions()
      loading = false
    }
  }

  function getRestrictionsForVehicle(vehicleType: VehicleTypeEnum | undefined) {
    if (!vehicleType)
      return null

    return restrictions.value?.[vehicleType]
  }

  function getRestrictionsForVehicleAndCountry(vehicleType: VehicleTypeEnum | undefined, country: CountryEnum | undefined) {
    if (!vehicleType || !country)
      return null

    return getRestrictionsForVehicle(vehicleType)?.[country]
  }

  fetchRestrictions()

  return { restrictions, getRestrictionsForVehicle, getRestrictionsForVehicleAndCountry }
}
