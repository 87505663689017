<script setup lang="ts">
import FreeQuoteForm from '@/views/free-quote/create/FreeQuoteForm.vue'
import VehicleTypes from '@/views/free-quote/components/VehicleTypes.vue'
import { VehicleTypeEnum } from '@apiTypes'

defineProps({
  highlight: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits<{
  (e: 'update:highlight', value: boolean): void
}>()

const vehicleTypeRef = ref(VehicleTypeEnum.VAN)

function updateVehicleType(vehicleType: VehicleTypeEnum) {
  vehicleTypeRef.value = vehicleType
}
</script>

<template>
  <div id="free-quote" class="free-quote-wrapper" @click="emit('update:highlight', false);">
    <div class="row justify-content-center pt-5">
      <div class="col-auto text-light">
        <h4>
          {{ $t('Get your price in minutes') }}
        </h4>
      </div>
    </div>
    <div class="row justify-content-center pb-3">
      <div class="col-md-6 text-light text-center">
        <h1 class="mb-3">
          {{ $t('Pickup in 2 hours') }}
        </h1>
        <h5 class="mb-md-5">
          {{
            $t('Get express road shipment around Europe with a full control of your shipment with live shipment dashboard.') }}
        </h5>
      </div>
    </div>
    <div class="row justify-content-center position-relative mt-sm-0 mt-5">
      <div class="col-lg-9 col-12">
        <div class="card rounded-4 position-relative">
          <div class="green-pill">
            <span class="badge rounded-pill bg-success p-2 px-4">{{ $t('Over 40,000 items delivered in last year') }}</span>
          </div>
          <div class="card-body" :class="{ 'highlight-border': highlight }">
            <VehicleTypes @vehicle-type-changed="updateVehicleType" />
            <FreeQuoteForm :vehicle-type="vehicleTypeRef" />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <h2 class="big-green-pill position-absolute translate-middle-y">
        <span class="badge rounded-pill bg-success px-3">{{ $t('Get your price in 3 minutes. Pay Later!') }}</span>
      </h2>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@styles/abstracts/colors';
@import '@styles/abstracts/variables';

.free-quote-wrapper {
  padding-bottom: 20%;

  .card {
    background-color: white;

    .card-body {
      padding: 1.5rem 2rem 0.3rem 2rem;
    }
  }
}

.green-pill {
  position: absolute;
  right: -2%;
  z-index: 2;
  transform: translateY(-50%);
}
.highlight-border{
    box-shadow: 0 0 0 0.25rem rgba($secondary, 0.75);
    border-radius: 20px;
}

@media (max-width: 768px) {
  .big-green-pill {
    top: 93%;
    font-size: $h4-font-size;
  }
}

@media (min-width: 768px) {
    .big-green-pill {
      top: 80%;
  }
}
</style>
