import { PackageTypeEnum, VehicleTypeEnum } from '@apiTypes'

export interface Package {
  package_type: PackageTypeEnum
  vehicle_type: Array<VehicleTypeEnum>
  length: number | undefined
  width: number | undefined
  height: number | undefined
  count: number | undefined
  unit_weight: number | undefined
  stackable: boolean | undefined
  disabledFields: Array<string> | undefined
}

export const packages: Package[] = [
  {
    package_type: PackageTypeEnum.EntireVehicle,
    vehicle_type: [VehicleTypeEnum.VAN],
    length: 420,
    width: 200,
    height: 200,
    count: 1,
    unit_weight: 1000,
    stackable: false,
    disabledFields: ['length', 'width', 'height', 'unit_weight'],
  },
  {
    package_type: PackageTypeEnum.EntireVehicle,
    vehicle_type: [VehicleTypeEnum.SMALL_LORRY],
    length: 600,
    width: 245,
    height: 240,
    count: 1,
    unit_weight: 2800,
    stackable: false,
    disabledFields: ['length', 'width', 'height', 'unit_weight'],
  },
  {
    package_type: PackageTypeEnum.EntireVehicle,
    vehicle_type: [VehicleTypeEnum.BIG_LORRY],
    length: 700,
    width: 245,
    height: 250,
    count: 1,
    unit_weight: 5000,
    stackable: false,
    disabledFields: ['length', 'width', 'height', 'unit_weight'],
  },
  {
    package_type: PackageTypeEnum.EntireVehicle,
    vehicle_type: [VehicleTypeEnum.LKW],
    length: 1360,
    width: 245,
    height: 270,
    count: 1,
    unit_weight: 24000,
    stackable: false,
    disabledFields: ['length', 'width', 'height', 'unit_weight'],
  },
  {
    package_type: PackageTypeEnum.EuroPallet,
    vehicle_type: [VehicleTypeEnum.VAN, VehicleTypeEnum.SMALL_LORRY, VehicleTypeEnum.BIG_LORRY, VehicleTypeEnum.LKW],
    length: 120,
    width: 80,
    height: undefined,
    count: 1,
    unit_weight: undefined,
    stackable: false,
    disabledFields: ['length', 'width'],
  },
  {
    package_type: PackageTypeEnum.IndustrialPallet,
    vehicle_type: [VehicleTypeEnum.VAN, VehicleTypeEnum.SMALL_LORRY, VehicleTypeEnum.BIG_LORRY, VehicleTypeEnum.LKW],
    length: 120,
    width: 100,
    height: undefined,
    count: 1,
    unit_weight: undefined,
    stackable: false,
    disabledFields: ['length', 'width'],
  },
  {
    package_type: PackageTypeEnum.EuroGitterbox,
    vehicle_type: [VehicleTypeEnum.VAN, VehicleTypeEnum.SMALL_LORRY, VehicleTypeEnum.BIG_LORRY, VehicleTypeEnum.LKW],
    length: 123,
    width: 84,
    height: 97,
    count: 1,
    unit_weight: undefined,
    stackable: false,
    disabledFields: ['length', 'width'],
  },
  {
    package_type: PackageTypeEnum.IBCContainer,
    vehicle_type: [VehicleTypeEnum.VAN, VehicleTypeEnum.SMALL_LORRY, VehicleTypeEnum.BIG_LORRY, VehicleTypeEnum.LKW],
    length: 120,
    width: 100,
    height: 116,
    count: 1,
    unit_weight: undefined,
    stackable: false,
    disabledFields: ['length', 'width'],
  },
  {
    // todo change 1 to undefined and fix it in packaging algo
    package_type: PackageTypeEnum.Custom,
    vehicle_type: [VehicleTypeEnum.VAN, VehicleTypeEnum.SMALL_LORRY, VehicleTypeEnum.BIG_LORRY, VehicleTypeEnum.LKW],
    length: 1,
    width: 1,
    height: undefined,
    count: 1,
    unit_weight: undefined,
    stackable: false,
    disabledFields: [],
  },
]

export function getAllPackagesForVehicleType(vehicleType: VehicleTypeEnum) {
  return packages.filter(pkg => pkg.vehicle_type.includes(vehicleType))
}
