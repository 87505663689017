import type { MapLayer } from '@gmap-vue/v3/components'
import type { Ref } from 'vue'
import { ref } from 'vue'

export function useGoogleMaps(): {
  mapRef: Ref<typeof MapLayer | null>
  reverseGeocode: (lat: number, lng: number) => Promise<string | null>
  updateMapBounds: (path: { lng: number; lat: number }[]) => void } {
  const mapRef = ref<typeof MapLayer | null>(null)

  function updateMapBounds(path: { lng: number; lat: number }[]) {
    if (mapRef.value) {
      const bounds = new google.maps.LatLngBounds()

      path.forEach(coord => bounds.extend(coord))
      mapRef.value.fitBounds(bounds)
    }
  }

  async function reverseGeocode(lat: number, lng: number): Promise<string | null> {
    const geocoder = new google.maps.Geocoder()
    try {
      const response = await geocoder.geocode({ location: { lat, lng } })
      if (response.results && response.results.length > 0) {
        for (const result of response.results) {
          const postalCodeComponent = result.address_components.find(component =>
            component.types.includes('postal_code'),
          )

          if (postalCodeComponent)
            return postalCodeComponent.short_name
        }
      }

      return ''
    }
    catch (error) {
      console.error('Reverse geocoding failed:', error)

      return ''
    }
  }

  return {
    mapRef,
    updateMapBounds,
    reverseGeocode,
  }
}
