import type { Ref } from 'vue'
import type { ToastInterface } from 'vue-toastification'
import { useTransportRestrictions } from '@/composables/transport-restrictions'
import i18n from '@/plugins/i18n'
import { Countries } from '@/utils/countries'
import type { Vehicle } from '@/utils/vehicle'
import type { QuoteByHash, QuoteByID, TransportRestriction, Waypoint } from '@apiTypes'
import { RuleTypeNameEnum, TypeEnum } from '@apiTypes'

export interface AddressCheckResult {
  allowed?: boolean
  error?: string
  warning?: string
}

export interface AddressCheck {
  pickup: AddressCheckResult
  delivery: AddressCheckResult
  global: AddressCheckResult
}

export function collectAndShowMessages(addressCheck: AddressCheck, toast: ToastInterface) {
  const errors = new Set()
  const warnings = new Set()

  Object.values(addressCheck).forEach(entry => {
    if (!entry.allowed) {
      if (entry.error)
        errors.add(entry.error)

      if (entry.warning)
        warnings.add(entry.warning)
    }
  })

  errors.forEach(error => toast.error(error))
  warnings.forEach(warning => toast.warning(warning))
}

export function checkAddresses(quoteRef: Ref<QuoteByID | QuoteByHash | Partial<QuoteByID>>, transportRestrictionsRef: Ref<TransportRestriction[]>) {
  const addressCheck: AddressCheck = { pickup: {}, delivery: {}, global: {} }
  const quote = unref(quoteRef)
  const transportRestrictions: TransportRestriction[] = unref(transportRestrictionsRef)

  const pickupAddress = quote.waypoints?.find(wp => wp.type === TypeEnum.Pickup)
  const deliveryAddress = quote.waypoints?.find(wp => wp.type === TypeEnum.Delivery)

  addressCheck.pickup = checkAddress(pickupAddress, TypeEnum.Pickup, transportRestrictions)
  addressCheck.delivery = checkAddress(deliveryAddress, TypeEnum.Delivery, transportRestrictions)

  addressCheck.global = checkAddressUniqueness(pickupAddress, deliveryAddress)

  return addressCheck
}

function checkAddress(address: Waypoint | undefined, type: TypeEnum, transportRestrictions: TransportRestriction[]): AddressCheckResult {
  const { t } = i18n.global
  const result: AddressCheckResult = { allowed: false }
  if (!address || !address.country || !address.city)
    return { ...result, error: t('You need to specify { type } which has at least city and country.', { type }) }
  else if (isDisallowedCountryOrRegion(address, transportRestrictions))
    return { ...result, warning: t('Looks like you would like to { loadType } goods in a country or region our system is not able to quote automatically. Don\'t worry, we\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.', { loadType: type === TypeEnum.Pickup ? t('load') : t('unload') }) }
  else
    return { allowed: true } as AddressCheckResult
}

function isDisallowedCountryOrRegion(address: Waypoint, transportRestrictions: TransportRestriction[]) {
  if (!Countries.includes(address.country))
    return true

  const disallowedRule: TransportRestriction = (transportRestrictions[address.country])?.find(item => item.rule_type_name === RuleTypeNameEnum.DISALLOWED_REGION)

  if (disallowedRule)
    return (isPostalCodeInRegionList(address.postcode, disallowedRule.region_list || []))

  return false
}

function isPostalCodeInRegionList(postcode: string | undefined, regionList: string[]) {
  if (!postcode || !regionList)
    return false

  return regionList.some(region => postcode.startsWith(region) || region === postcode)
}

function checkAddressUniqueness(...addresses: Waypoint[]) {
  const result: AddressCheckResult = { allowed: false }
  if (!addresses)
    return result

  const addressesCoordinates = []
  for (const address of addresses.values())
    addressesCoordinates.push(address.point?.coordinates?.toString())

  result.allowed = new Set(addressesCoordinates).size === addresses.length

  const { t } = i18n.global
  if (!result.allowed)
    result.error = t('Addresses cannot be the same.')

  return result
}

export function isLTLAllowedForVehicleInCountryOrRegion(waypoints: Waypoint[], vehicle: Vehicle, totalWeight: number, tailLift: boolean) {
  for (const waypoint of waypoints) {
    const country = waypoint.country || undefined
    const postcode = waypoint.postcode || undefined
    const vehicleType = vehicle.type

    const { getRestrictionsForVehicleAndCountry } = useTransportRestrictions()

    const restrictions: TransportRestriction[] = getRestrictionsForVehicleAndCountry(vehicleType, country)

    if (!restrictions)
      return true

    if (!isAllowedByRestrictions(restrictions, postcode, vehicle, totalWeight, tailLift))
      return false
  }

  return true
}

function isAllowedByRestrictions(restrictions: TransportRestriction[], postcode: string | undefined, vehicle: Vehicle, totalWeight: number, tailLift: boolean) {
  const restrictionTypes = restrictions.map(item => item.rule_type_name)
  for (const ruleType of restrictionTypes) {
    switch (ruleType) {
      case RuleTypeNameEnum.LTL_DISALLOWED_COUNTRY:
        return false
      case RuleTypeNameEnum.LTL_DISALLOWED_REGION:
        if (isRestrictedRegion(restrictions, ruleType, postcode))
          return false
        break
      case RuleTypeNameEnum.LTL_PARTIALLY_ALLOWED_COUNTRY:
        if (!doesSuitPartialLTL(vehicle, totalWeight, tailLift))
          return false
        break
      case RuleTypeNameEnum.LTL_PARTIALLY_ALLOWED_REGION:
        if (isRestrictedRegion(restrictions, ruleType, postcode) && !doesSuitPartialLTL(vehicle, totalWeight, tailLift))
          return false

        break
    }
  }

  return true
}

function isRestrictedRegion(restrictions: TransportRestriction[], ruleType: RuleTypeNameEnum, postcode: string | undefined) {
  const regionList = restrictions.find(item => item.rule_type_name === ruleType)?.region_list || []

  return isPostalCodeInRegionList(postcode, regionList)
}

function doesSuitPartialLTL(vehicle: Vehicle, totalWeight: number, tailLift: boolean) {
  if (tailLift && vehicle.tailLiftWeight > 0)
    return totalWeight <= vehicle.tailLiftWeight

  return totalWeight <= vehicle.ltlPartialWeight
}

export function isLTLWeightLimitExceeded(vehicle: Vehicle, totalWeight: number, tailLift: boolean) {
  if (tailLift && vehicle.tailLiftWeight > 0)
    return totalWeight > vehicle.tailLiftWeight

  return totalWeight > vehicle.ltlWeight
}
