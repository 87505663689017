import { useToast } from 'vue-toastification'
import { useI18n } from 'vue-i18n'
import type { ComputedRef } from 'vue'
import { computed } from 'vue'
import type { Good } from '@apiTypes'
import { getAllPackagesForVehicleType } from '@/utils/packaging'
import { BinPacking2D, vehicles } from '@/utils/vehicle'
import { useQuoteStore } from '@/stores/quote'

export function useGoods(): {
  updateGood: (updatedGood: Good) => void
  removeGood: (identifier: number) => void
  goods: ComputedRef<Good[] | undefined>
  totalWeight: ComputedRef<number | undefined>
  addGood: () => void
  validateDimensions: () => boolean
  checkFitAndWarn: () => boolean } {
  const quoteStore = useQuoteStore()
  const toast = useToast()
  const { t } = useI18n()
  const goods = computed(() => quoteStore.getQuoteByID.goods)
  const totalWeight = computed(() => quoteStore.totalWeight)
  const vehicle = computed(() => vehicles.find(item => item.type === quoteStore.getQuoteByID.vehicle_type))
  const vehiclePackages = computed(() => getAllPackagesForVehicleType(vehicle.value?.type))

  function createGood(): Good {
    const defaultPackage = vehiclePackages.value[0]

    return {
      count: defaultPackage.count,
      unit_weight: defaultPackage.unit_weight,
      height: defaultPackage.height,
      length: defaultPackage.length,
      width: defaultPackage.width,
      package_type: defaultPackage.package_type,
      stackable: defaultPackage.stackable,
    } as Good
  }

  function addGood() {
    const newGood = createGood()

    quoteStore.addGood(newGood)
    checkFitAndWarn()
  }

  function updateGood(updatedGood: Good) {
    quoteStore.updateGood(updatedGood)
    validateDimensions()
    checkFitAndWarn()
  }

  function removeGood(identifier: number) {
    quoteStore.removeGood(identifier)
  }

  function checkFitAndWarn() {
    const binPacking = new BinPacking2D(vehicle.value, goods.value ?? [])
    const fits = binPacking.fitPackages().fits
    if (!fits) {
      toast.dismiss('floorToast')
      toast.warning(t('The dimensions of your packages specified in your transportation request exceed the floor space of the vehicle you requested, but don\'t worry, we\'ll do our best to help you out! Choose a larger vehicle type or send us an email about your request and we will get back to you as soon as possible.'), {
        id: 'floorToast',
      })

      return false
    }

    return true
  }

  function validateDimensions() {
    let isValid = true

    for (const good of goods.value || []) {
      if (good.height !== null && good.height !== undefined && good.height > vehicle.value?.dimensions?.height) {
        toast.dismiss('heightToast')
        toast.warning(t('You\'ve reached the height limit of selected vehicle, but don\'t worry, we\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.'), {
          id: 'heightToast',
        })
        isValid = false
      }

      if (good.width !== null && good.width !== undefined && good.width > vehicle.value.dimensions?.width) {
        toast.dismiss('widthToast')
        toast.warning(t('You\'ve reached the width limit of selected vehicle, but don\'t worry, we\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.'), {
          id: 'widthToast',
        })
        isValid = false
      }

      if (good.length !== null && good.length !== undefined && good.length > vehicle.value?.dimensions?.length) {
        toast.dismiss('lengthToast')
        toast.warning(t('You\'ve reached the width limit of selected vehicle, but don\'t worry, we\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.'), {
          id: 'lengthToast',
        })
        isValid = false
      }

      if (totalWeight.value !== undefined && totalWeight.value > vehicle.value.weight) {
        toast.dismiss('weightToast')
        toast.warning(t('The vehicle you chose for your transportation request cannot accommodate the weight of your shipment, but don\'t worry, we\'ll do our best to help you out! Choose a larger vehicle type or send us an email about your request and we will get back to you as soon as possible.'), {
          id: 'weightToast',
        })
        isValid = false
      }
    }

    return isValid
  }

  return { addGood, updateGood, removeGood, goods, totalWeight, validateDimensions, checkFitAndWarn }
}
