import { acceptHMRUpdate } from 'pinia'
import api from '@api'
import type { TransportRestriction } from '@apiTypes'

export const useTransportRestrictionStore = defineStore('transportRestriction', {
  state: () => ({
    restrictions: {} as TransportRestriction[] | null,
    loading: false,
    error: null as string | null,
  }),

  actions: {
    async fetchRestrictions() {
      this.loading = true
      this.error = null

      try {
        const response = await api.transportRestrictionList()

        this.restrictions = response.data
      }
      catch (err) {
        this.error = err instanceof Error ? err.message : 'An unknown error occurred'
      }
      finally {
        this.loading = false
      }

      return this.restrictions
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useTransportRestrictionStore, import.meta.hot))
